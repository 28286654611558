import React from "react";
import Banner from "../assets/img/2.png"

const Support = () => {
    return (
        <div className="support-container container py-5">
            <div className="row align-items-center position-relative">
                <div className="col-xl-2 support-image-container">
                    <img src={Banner} alt="football" className="banner-img" />
                </div>
                <div className="col-xl-7 ps-xl-5 px-4">
                    <h3 className="text-white support-title">
                        Azure hızına yetişebilecek misin?
                    </h3>
                    <p className="mb-0 text-white">
                        20 GigaBit altyapıya sahip +15 sunucumuz ile yayın hızı
                        konusunda iddialıyız!
                    </p>
                    <p className="text-white">
                        İnternet hızının yeterli olup olmadığıyla ilgili
                        ücretsiz olarak müşteri temsilcimizden bilgi
                        alabilirsiniz.
                    </p>
                </div>
                <div className="col-xl-3 text-center mt-xl-0 mt-4">
                    <button className="btn btn-white">
                        <i className="fa-solid fa-headset me-2"></i> <a href="https://api.whatsapp.com/send?phone=447365957614">Canlı Destek</a>
                    </button>
                </div>
            </div>
            <div className="bg-effect-1"></div>
            <div className="bg-effect-2"></div>
            <div className="bg-effect-3"></div>
        </div>
    );
};

export default Support;
