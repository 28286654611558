import React from 'react'

const Test = () => {
    return (
        
        <div className="container">
            <div className='ch-list-container'>
            <h2 className="page-title">
                 ÜCRETSİZ TEST YAYINI
                <span style={{ color: "#483285" }}>.</span> </h2>
                <div className="w-100 text-center mt-3">
                    <div className="w-75 mx-auto carousel-mobile-title">
                        <p style={{ color: "#767676" }}>
                        Test sisteminde sadece Outlook,GMail ve iCloud sağlayıcıları geçerlidir. Kullanıcı adında türkçe karakterler ve boşluk kullanmayınız! Mail adresinizin kesinlikle doğru olması gerekmektedir mail adresinize onay kodu yollanacaktır. Lütfen onay kodunuz gelmedi ise spam kutusunu kontrol ediniz.


                        </p>
                    </div>
                </div>

<div className='iframe'>
<div class="bg-white shadow-sm p-3 my-4">
                <iframe src="https://test.tvazure.net" className="dank"></iframe>
                </div></div>
            <div className='ch-container'>
            <div className='container text-center'>
            Uyarı! 4 büyük maçlarında bütün test yayınları kapatılır.
            </div>
        </div>
            </div>
            
        </div>

        

      
    )
}

export default Test;