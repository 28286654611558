import React from 'react'
import Banner from "../assets/img/2.svg"
import Confetti from "../assets/img/1.svg"

const Faqs = () => {
    return (
        <div className='container faqs-container' id='sorular'>
            <h2 className="page-title">
                SIKÇA SORULAN SORULAR
                <span style={{ color: "#483285" }}>.</span>
            </h2>
            <p className='text-center' style={{ color: "#818181" }}>IPTV hizmetimiz hakkında daha fazla bilgi almak ve aklınıza takılan sorulara cevap bulmak için bizimle iletişime geçin.</p>
            <div className="row align-items-end mt-5">
                <div className="col-xl-6">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item shadow mb-3">
                            <h2 className="accordion-header">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                İPTV izlemek için internet hızım kaç mbps olmalıdır?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Iptv yayınlarını sorunsuz izleyebilmeniz için internet hızınız 10 megabitin üzerinde olmalıdır. İnternet hızınız 10 megabitin üzerinde olduğu sürece yayınlarda herhangi bir kesinti yaşamadan problemsiz şekilde izleyebilirsiniz.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item shadow mb-3">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Yayınları istediğim yerden izleyebilirmiyim?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Bilgisayar, cep telefonu veya akıllı televizyonunuzun olduğu her yerde yayınları izleyebilirsiniz. Yayınları sorunsuz izleyebilmek için internet hızınızın 10 megabitin üzerinde olması gerekmektedir. İnternet hızınız 10 megabitin üzerinde olduğu sürece kesintisiz izleyebilirsiniz.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item shadow mb-3">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                İPTV Uygulamaları Hangileridir?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                IPTV kullanabilmek için aracı bir uygulama (ücretli veya ücretsiz) kullanmanız gerekmektedir. Kullanacağınız cihaza değişiklik gösterir. En popüler uygulamalar şunlardır: Smart iptv, İptv Smarters Pro, GSE iptv, Set iptv, Net iptv                                </div>
                            </div>
                        </div>
                        <div className="accordion-item shadow mb-3">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Ödeme yaptığımda ne zaman aktif olur?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                Size uygun paketi talimatlara göre satın alıktan sonra kullanıcı bilgileriniz anında oluşturulur. Kullanıcı bilgilerinizi canlı destek ekibimize bağlanarak talep edebilirsiniz.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item shadow">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Hangi kanallar var? IPTV kanal listesine nasıl ulaşırım?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
Binlerce kanalımız sürekli olarak güncellenmektedir. <strong><a href="/kanal-listesi">Kanal listemize ulaşmak için buraya tıklayın.</a></strong></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-6 confetti-container-mobile">
                    <div className='px-xl-5 position-relative'>
                        <img src={Banner} alt="banner" width="100%"  />
                        <div className='confetti-img'>
                            <img src={Confetti} alt="banner" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs