import React, { useState, useEffect } from "react";
import Logo from "../assets/img/logo_w.svg";
import LogoT from "../assets/img/logo.svg";
import { Link } from "react-router-dom"

const HeaderTwo = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {matches && (
                <>
                    {
                        scrollPosition >= 90 ?
                            <div className="header-headertwo">
                                <div className="bg-light shadow rounded headertwo-container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="logo">
                                        <a href="/"><img src={Logo} width="200px" height="auto" alt=""/></a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <ul className="list-unstyled d-flex mb-0 navbartwo-items">
                                                <li>
                                                    <a href="/">ANA SAYFA</a>
                                                </li>
                                                <li>
                                                    <Link to="https://azurehost.net/payment/">SATIN AL</Link>
                                                </li>
                                                <li>
                                                    <Link to="/kanal-listesi">KANAL LİSTESİ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/test">TEST</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary customertwo-panel-btn fw-bold">
                                            <Link to="https://kurulum.azureiptv.com/">KURULUM</Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div id="headertwo">
                                
                                <div className="container shadow rounded  headertwo-color headertwo-container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="logo">
                                        <a href="/"><img src={Logo} width="200px" height="auto" alt=""/></a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                        <ul className="list-unstyled d-flex mb-0 navbartwo-items">
                                                <li>
                                                    <a href="/">ANA SAYFA</a>
                                                </li>
                                                <li>
                                                    <Link to="https://azurehost.net/payment/">SATIN AL</Link>
                                                </li>
                                                <li>
                                                <a href="/kanal-listesi">KANAL LİSTESİ</a>
                                                </li>
                                                <li>
                                                <a href="/test">TEST YAYINI</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary customertwo-panel-btn fw-bold">
                                            <Link to="https://kurulum.azureiptv.com/">KURULUM</Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
            )}
            {!matches && (
                <div className="mobile-headertwo">
                    <div className="d-flex align-items-center justify-content-between p-3 shadow">
                        <div>
                        <a href="/"><img src={Logo} width="175px" height="auto" alt=""/></a>
                        </div>
                        <div>
                            <button className="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                <i className="fa-solid fa-bars"></i>
                            </button>
                            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                                    <a href="/"><img src={LogoT} width="200px" height="auto" alt=""/></a>
                                    </h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="list-unstyled mobile-menu">
                                        <li><a href="/">ANA SAYFA</a></li>
                                        <li>
                                            <Link to="https://azurehost.net/payment/index.php">SATIN AL</Link>
                                        </li>
                                        <li>
                                                <a href="/kanal-listesi">KANAL LİSTESİ</a>
                                                </li>
                                                <li>
                                                <a href="/test">TEST YAYINI</a>
                                                </li>
                                    </ul>
                                    <div className="mt-5">
                                        <button className="mobile-headertwo-customer-btn fw-bold"><Link to="https://kurulum.azureiptv.com/">KURULUM</Link></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HeaderTwo;
