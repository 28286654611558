import React from "react";

const Dealer = () => {
    return (
        <div className="support-container container py-4 dealer-container" id="bayilik">
            <div className="row align-items-center py-4">
                <div className="col-xl-5 px-xl-5 mb-xl-0 mb-4">
                    <h3 className="text-white support-title">
                        Bayimiz Olun
                    </h3>
                    <p className="mb-0 text-white">
                        Azure iPTV altyapısını kullanarak, kolayca kendi müşterilerinizi oluşturun. Size özel yönetim panelinden, kolayca birkaç adımla müşterilerinizi oluşturabilir ve yönetebilirsiniz.
                    </p>
                </div>
                <div className="col-xl-4 mb-xl-0 mb-4">
                    <div className="text-white mb-3">
                        <i className="fa-solid fa-check me-1"></i> <span className="dealer-card-text">Yüksek Kar Oranlarıyla Kazanç Sağlarsınız.</span>
                    </div>
                    <div className="text-white mb-3">
                        <i className="fa-solid fa-check me-1"></i> <span className="dealer-card-text">7/24 Kesintisiz Bayi Destek Hizmeti Ağı Hizmeti.</span>
                    </div>
                    <div className="text-white">
                        <i className="fa-solid fa-check me-1"></i> <span className="dealer-card-text">Yüksek Performansla Sunucularla IPTV Hizmeti Deneyimi.</span>
                    </div>
                </div>
                <div className="col-xl-3 text-center">
                    <button className="btn btn-white btn-dealer">
                    <a href="https://api.whatsapp.com/send?phone=447365957614">HEMEN BAŞVUR</a>
                    </button>
                </div>
            </div>
            <div className="bg-effect-1"></div>
            <div className="bg-effect-2"></div>
            <div className="bg-effect-3"></div>
        </div>
    );
};

export default Dealer;
