import React from "react";
import Tv from "../assets/img/tv.png"

const InfoCard = () => {
    return (
        <div className="info-card-container" id="neden-biz">
            <div className="container text-white">
                <div className="row align-items-center">
                    <div className="col-xl-5 mb-xl-0 mb-4 info-card-mobile-tv">
                        <img src={Tv} alt="589" width="100%" height="auto" />
                    </div>
                    <div className="col-xl-7 ps-xl-5">
                        <h3 className="mb-3 info-card-title text-xl-start text-center">
                            Kullanıcı Dostu Özelliklerimiz
                        </h3>
                        <p className="mb-0 info-card-text text-xl-start text-center">
                            Adından da anlaşılacağı üzere özelliklerimiz sizler
                            için tasarlandı. Bunlar sayesinde, izlemek
                            istediklerinizi yönetebilir, her gün eklenen yeni
                            içeriklere göz atabilir, sınırsız bir şekilde
                            servisin tadını çıkarabilirsiniz.
                        </p>
                        <div className="d-flex align-items-center mt-4 info-card-btns">
                            <button className="btn btn-white me-xl-3 mb-xl-0 mb-3">
                                <i className="fa-solid fa-headset me-2"></i>{" "}
                                <a href="https://api.whatsapp.com/send?phone=447365957614">Canlı Destek</a>
                            </button>
                            <button className="btn btn-white">
                                <i className="fa-brands fa-whatsapp me-2"></i>{" "}
                                <a href="https://api.whatsapp.com/send?phone=447365957614">Whatsapp</a>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row info-card-2 shadow">
                    <div className="col-xl-4 p-xl-4">
                        <div className="d-flex align-items-center border-end">
                            <div className="me-4">
                                <i className="fa-solid fa-gauge-high"></i>
                            </div>
                            <div>
                                <h3 className="mb-1">Hızlı İşlem</h3>
                                <p className="mb-0">Yüksek işlem hızımızla ödeme sonrası hesaplarınız anında aktifleştirilir.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 p-xl-4">
                        <div className="d-flex align-items-center border-end">
                            <div className="me-4">
                                <i className="fa-solid fa-shield-halved"></i>
                            </div>
                            <div>
                                <h3 className="mb-1">Bilgi Güvenliği</h3>
                                <p className="mb-0">Tüm işlemler ve ödemeler 3D Secure Ödeme sistemi ile güvendedir.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 p-xl-4">
                        <div className="d-flex align-items-center">
                            <div className="me-4">
                                <i className="fa-solid fa-receipt"></i>
                            </div>
                            <div>
                                <h3 className="mb-1">Garantili Hizmet</h3>
                                <p className="mb-0">Satın aldığınız paketlerde herhangi bir sorun olduğunda anında telafi ediyoruz.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info-card-bg"></div>
        </div>
    );
};

export default InfoCard;
