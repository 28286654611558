import React from "react";

const DownArrow = () => {
    return (
        <div className="container down-arrow-container">
            <a href="#icerik-container">
                <button className="down-arr-btn btn shadow">
                    <i className="fa-solid fa-arrow-down"></i>
                </button>
            </a>
        </div>
    );
};

export default DownArrow;
