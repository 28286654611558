import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HeaderTwo from "../components/HeaderTwo";
import Ch from "../components/Ch";

const ChList = () => {
    return (
        <>
            <HeaderTwo />
            <Ch />

        </>
    )
}

export default ChList