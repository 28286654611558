import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Copyright from "./components/Copyright";
import Homepage from "./pages/Homepage"
import ChList from "./pages/ChList"
import TestAl from "./pages/TestAl"
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
    return (
        <>
            <BrowserRouter>
            <ScrollToTop>
              
                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/kanal-listesi" element={<ChList />} />
                    <Route path="/test" element={<TestAl />} />
                    <Route path="/header" element={<Header />} />
                </Routes>
                <Footer />
                <Copyright />
                </ScrollToTop>
            </BrowserRouter>
        </>
    );
}

export default App;
