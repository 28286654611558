import React, { useState, useEffect } from "react";
import Logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom"

const Header = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )
    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);

    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const closeMenu = () => {
      setMenuOpen(false);
    };
  

    return (
        <>
            {matches && (
                <>
                    {
                        scrollPosition >= 90 ?
                            <div className="header-header">
                                <div className="bg-light shadow rounded header-container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="logo">
                                            <a href="/"><img src={Logo} width="200px" height="auto" alt="" /></a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <ul className="list-unstyled d-flex mb-0 navbar-items">
                                                <li>
                                                    <a href="/">ANA SAYFA</a>
                                                </li>
                                                <li>
                                                    <a href="#icerik-container">İÇERİK</a>
                                                </li>
                                                <li>
                                                    <a href="#iptv-paketleri">IPTV PAKETLERİ</a>
                                                </li>
                                                <li>
                                                    <a href="#sorular">S.S.S</a>
                                                </li>
                                                <li>
                                                    <a href="#bayilik">BAYİLİK</a>
                                                </li>
                                                <li>
                                                    <Link to="/kanal-listesi">KANAL LİSTESİ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/test">TEST YAYINI</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary customer-panel-btn fw-bold">
                                            <a href="https://kurulum.azureiptv.com/">KURULUM</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div id="header">
                                <div className="container bg-light shadow rounded header-container">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className="logo">
                                        <a href="/"><img src={Logo} width="200px" height="auto" alt="" /></a>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <ul className="list-unstyled d-flex mb-0 navbar-items">
                                            <li>
                                                    <a href="/">ANA SAYFA</a>
                                                </li>
                                                <li>
                                                    <a href="#icerik-container">İÇERİK</a>
                                                </li>
                                                <li>
                                                    <a href="#iptv-paketleri">IPTV PAKETLERİ</a>
                                                </li>
                                                <li>
                                                    <a href="#sorular">S.S.S</a>
                                                </li>
                                                <li>
                                                    <a href="#bayilik">BAYİLİK</a>
                                                </li>
                                                <li>
                                                    <Link to="/kanal-listesi">KANAL LİSTESİ</Link>
                                                </li>
                                                <li>
                                                    <Link to="/test">TEST YAYINI</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary customer-panel-btn fw-bold">
                                            <a href="https://kurulum.azureiptv.com/">KURULUM</a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
            )}
            {!matches && (
            <div className="mobile-header">

                <nav className={`navbar navbar-expand-md navbar-light bg-light mobile-header ${isMenuOpen ? 'show-menu' : ''}`}>
      <a className="navbar-brand" href="/">
        <img src={Logo} width="175px" height="auto" alt="" />
      </a>
      <button
        className={`btn btn-primary ${isMenuOpen ? 'collapsed' : ''}`}
        type="button"
        onClick={toggleMenu}
        aria-controls="mobileNav"
        aria-expanded={isMenuOpen ? 'true' : 'false'}
        aria-label="Toggle navigation"
        style={{ marginLeft: 'auto' }}
      >
        <i className="fa-solid fa-bars"></i>
      </button>

      <div className={`offcanvas offcanvas-end ${isMenuOpen ? 'show' : ''}`} id="offcanvasRight">
      <div className="offcanvas-header">
                                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                                     <a href="/"><img src={Logo} width="200px" height="auto" alt="" /></a>
                                    </h5>
                                    <button type="button" className="btn-close" onClick={closeMenu} />
                                </div>
                                <div className="offcanvas-body">
        <ul className="list-unstyled mobile-menu">
          <li className="nav-item">
            <a className="nav-link" href="/" onClick={closeMenu}>
              ANA SAYFA
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#icerik-container" onClick={closeMenu}>
              İÇERİK
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#iptv-paketleri" onClick={closeMenu}>
              iPTV PAKETLERİ
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#neden-biz" onClick={closeMenu}>
              NEDEN BİZ
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#sorular" onClick={closeMenu}>
              S.S.S
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#bayilik" onClick={closeMenu}>
              BAYİLİK
            </a>
          </li>
          <li>
                                            <Link to="/kanal-listesi">KANAL LİSTESİ</Link>
                                        </li>
                                        <li>
                                            <Link to="/test">TEST YAYINI</Link>
                                        </li>
        </ul>
        <div className="mt-5">
                                        <button className="mobile-header-customer-btn fw-bold">
                                        <Link to="https://kurulum.azureiptv.com/">KURULUM</Link>
                                        </button>
                                    </div>
        </div>
      </div>
    </nav>
    </div>
                
            )}
        </>
    );
};

export default Header;
