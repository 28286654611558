import React, { Component } from "react";
import Slider from "react-slick";

const Carousel = (props) => {

    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <div className="next-arrow" onClick={onClick}>
                <i className="fa-solid fa-arrow-right"></i>
            </div>
        );
    }

    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <div className="prev-arrow" onClick={onClick}>
                <i className="fa-solid fa-arrow-left"></i>
            </div>
        );
    }

    let settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 5,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                },
            },
        ],
    };
    return (
        <div id="icerik-container">
            <div className="container">
                <h2 className="page-title">
                    GÜNCEL VE KESİNTİSİZ İÇERİK
                    <span style={{ color: "#483285" }}>.</span>
                </h2>
                <div className="w-100 text-center mt-3">
                    <div className="w-75 mx-auto carousel-mobile-title">
                        <p style={{ color: "#767676" }}>
                            Bütün canlı kanallara ve premium dizi-film
                            içeriklerine HD kalitesinde ulaşın. <br /> iPTV
                            hizmetinizi televizyonda, telefonda, bilgisayarda
                            kısacası internete erişebildiğiniz her yerde
                            izleyebilirsiniz!
                        </p>
                    </div>
                </div>
                <div className="carousel-items">
                    <Slider {...settings}>
                        <div className="text-center">
                            <i className="fa-solid fa-film"></i>
                            <div className="carousel-item-text-box">
                                <h4>Şifreli Spor Kanalları</h4>
                                <p className="mb-0 mt-3">
                                    Bütün şifreli spor kanalları Azure iPTV'de!
                                    20 GigaBit destekli sunucularımızla maç
                                    keyfinizi kesintisiz yaşayın!
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <i className="fa-solid fa-tv"></i>
                            <div className="carousel-item-text-box">
                                <h4>Premium Dizi-Film Kanalları</h4>
                                <p className="mb-0 mt-3">
                                    Şifreli, premium kalitedeki bütün dizi-film kanallarına ücretsiz erişim sağlayın!
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <i className="fa-solid fa-photo-film"></i>
                            <div className="carousel-item-text-box">
                                <h4>VOD Dizi-Film</h4>
                                <p className="mb-0 mt-3">
                                    Güçlü sunucularımızda bulunan binlerce VOD içeriğe erişin. VOD Dizi-Filmleri istediğiniz zaman başlatın, durdurun ve devam edin!
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <i className="fa-brands fa-youtube"></i>
                            <div className="carousel-item-text-box">
                                <h4>Yurtdışı Kanalları</h4>
                                <p className="mb-0 mt-3">
                                    Farklı dillerde bulunan ve televizyonlarınızda izleyemediğiniz bütün kanallara erişin!
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <i className="fa-solid fa-video"></i>
                            <div className="carousel-item-text-box">
                                <h4>Premium Diziler</h4>
                                <p className="mb-0 mt-3">
                                    Netflix gibi platformlarda bulunan bütün Dizi-Filmleri dilediğiniz zaman izleyin!
                                </p>
                            </div>
                        </div>
                        <div className="text-center">
                            <i className="fa-solid fa-photo-film"></i>
                            <div className="carousel-item-text-box">
                                <h4>7/24 Canlı Destek</h4>
                                <p className="mb-0 mt-3">
                                    Canlı Destek konusunda iddialıyız! Dilediğiniz zaman bize ulaşabilirsiniz.
                                </p>
                            </div>
                        </div>
                    
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Carousel;
