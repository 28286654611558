import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../components/Header";
import Banner from "../components/Banner";
import DownArrow from "../components/DownArrow";
import Carousel from "../components/Carousel";
import Support from "../components/Support";
import Services from "../components/Services";
import InfoCard from "../components/InfoCard";
import Faqs from "../components/Faqs";
import Dealer from "../components/Dealer";

const Homepage = () => {
    return (
        <>
            <Header />
            <Banner />
            <DownArrow />
            <Carousel />
            <Support />
            <Services />
            <InfoCard />
            <Faqs />
            <Dealer />
        </>
    )
}

export default Homepage