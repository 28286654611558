import React, { useState } from "react";

const Banner = () => {
    const [user, setUser] = useState();
    const [time, setTime] = useState();
    const [price, setPrice] = useState();
    const [active, setActive] = useState(false);

    const paymentBtn = () => {
        if (user == 1 && time == 3) {
            setPrice(179);
        }
        else if (user == 1 && time == 1) {
            setPrice(219);
        }
        else if (user == 1 && time == 6) {
            setPrice(399);
        }
        else if (user == 1 && time == 12) {
            setPrice(599);
        }
        else if (user == 1 && time == 24) {
            setPrice(888);
        }
        else if (user == 2 && time == 3) {
            setPrice(459);
        }
        else if (user == 2 && time == 6) {
            setPrice(759);
        }
        else if (user == 2 && time == 12) {
            setPrice(1049);
        }
        else if (user == 2 && time == 24) {
            setPrice(1499);
        }
        else if (user == 2 && time == 1) {
            setPrice(399);
        }
        
        setActive(true);
    }

    return (
        <div id="banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-8">
                        <div className="w-75 mobile-banner-container">
                            <h1 className="banner-title mb-4">
                                MAÇ KEYFİNİ <br />
                                <span className="banner-title-bold">
                                    EVİNİZDE YAŞAYIN
                                </span>
                            </h1>
                            <h5 className="banner-subtitle mb-4">
                                Türkiye ve dünya kanalları, 90.000'den fazla
                                içerik, 7/24 hizmet veren müşteri temsilcisi,
                                profesyonel bir ekip ve kesintisiz yayın
                                deneyimiyle Azure iPTV siz ve aileniz için ideal
                                tercih!
                            </h5>
                            <h5 className="banner-subtitle bold-text mb-5">
                                Kalitesiz içerik yok, yayın kesilmesi ve donma
                                yok!
                            </h5>
                            <a href="#iptv-paketleri">
                                <button className="btn services-btn banner-services">
                                    PAKETLERİ İNCELE
                                    <i className="fa-solid fa-chevron-down ms-2" />
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="col-xl-4 my-xl-0 my-5">
                        <div className="card fast-order-card">
                            <h3 className="text-center fast-order-title">
                                HIZLI SİPARİŞ OLUŞTUR
                            </h3>
                            <p className="text-center fast-order-subtitle">
                                Sana uygun paketi seç, ödemeni yap, hesabın
                                anında aktifleştirilsin!
                            </p>
                            <div className="mb-4 position-relative">
                                <label className="mb-2">Kullanıcı Sayısı</label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={(e) => setUser(e.target.value)}
                                >
                                    <option selected />
                                    <option value="1">1 Kullanıcı</option>
                                    <option value="2">2 Kullanıcı</option>
                                </select>
                                <i className="fa-solid fa-user-group" />
                            </div>
                            <div className="position-relative">
                                <label className="mb-2">Üyelik Süresi</label>
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    onChange={(e) => setTime(e.target.value)}
                                >
                                    <option selected />
                                    <option value="1">1 Aylık</option>
                                    <option value="3">3 Aylık</option>
                                    <option value="6">6 Aylık</option>
                                    <option value="12">12 Aylık</option>
                                    <option value="24">24 Aylık</option>
                                </select>
                                <i className="fa-regular fa-calendar-days" />
                            </div>
                            {
                                price ?
                                    <div className="text-center banner-select-price mt-3">
                                        {price}<span style={{ fontSize: "26px" }}>₺</span>
                                    </div>
                                    :
                                    null
                            }
                            {
                                active ?
                                    <>
                                        <button className="btn fast-order-buy-btn py-2 mt-3">
                                            <a href="https://azurehost.net/payment" className="fast-order-btn-link">
                                                SATIN AL
                                            </a>
                                        </button>
                                        <button className="btn fast-order-buy-btn-white py-2 mt-3 " onClick={paymentBtn}>
                                            HESAPLA
                                        </button>
                                    </>
                                    :
                                    <button className="btn fast-order-buy-btn py-2 mt-5 " onClick={paymentBtn}>
                                        HESAPLA
                                    </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
