import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HeaderTwo from "../components/HeaderTwo";
import Test from "../components/Test";

const TestAl = () => {
    return (
        <>
            <HeaderTwo />
            <Test />

        </>
    )
}

export default TestAl