import React from 'react'

const ChList = () => {
    return (
        <div className="container">
            <div className='ch-list-container'>
            <h2 className="page-title">
                KANAL LİSTESİ
                <span style={{ color: "#483285" }}>.</span> </h2>
                <div className="w-100 text-center mt-3">
                    <div className="w-75 mx-auto carousel-mobile-title">
                        <p style={{ color: "#767676" }}>
                            Kanal Listemiz sunucularımızla eş zamanlı olarak güncellenmektedir. Bu şekilde listemiz her zaman doğru bilgiler içerir.
                        </p>
                    </div>
                </div>

<div className='iframe'>
<div class="bg-white shadow-sm p-3 my-4">
                <iframe src="https://kanallist.tvazure.net" className="dank"></iframe>
                </div></div>
            <div className='ch-container'>
            <div className='container text-center'>
            Eklenmesini istediğiniz kanal mı var? Bize bu talebinizi iletebilirsiniz.
            </div>
        </div>
            </div>
            
        </div>

        

      
    )
}

export default ChList;