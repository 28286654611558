import React from 'react'

const Copyright = () => {
    return (
        <div className='copyright-container'>
            <div className='container text-center'>
                Copyright © 2023 All Rights Reserved Azure iPTV
            </div>
        </div>
    )
}

export default Copyright