import React, { useState } from "react";
import P1 from '../assets/img/p1.png'
import P2 from '../assets/img/p2.png'
import P3 from '../assets/img/p3.png'
import P4 from '../assets/img/p4.png'

const Services = () => {
    const [threeMonth, setThreeMonth] = useState(249);
    const [sixMonth, setSixMonth] = useState(399);
    const [twelveMonth, setTwelveMonth] = useState(599);
    const [thiryfourMonth, setThirtyfourMonth] = useState(989);

    return (
        <div id="iptv-paketleri" className="container my-5">
            <h2 className="page-title">
                iPTV PAKETLERİ
                <span style={{ color: "#483285" }}>.</span>
            </h2>
            <p className="text-center mb-0">
                Size uygun olan paketi seçip, ödemeyi yapın ve dakikalar
                içerisinde izlemeye başlayın!
            </p>
            <p className="text-center">
                Ödeme aşamasından sonra hesap bilgileriniz ve kurulum ile ilgili
                bilgilendirme ekranına yönlendirileceksiniz.
            </p>
            <div className="row mt-5 services-card-mobile-margin">
                <div className="col-xl-3">
                    <div className="card border-0 shadow text-center service-card">
                        <div className="card-header border-bottom pt-4 bg-transparent border-0">
                            <div className="service-card-icon mb-3">
                                <img src={P1} alt="icon" weight="auto" height="auto"/>
                            </div>
                            <h5 className="service-card-title mb-0">
                                3 AYLIK iPTV
                            </h5>
                            <p className="mb-0 service-card-price">
                                {threeMonth}<span>₺</span>
                            </p>
                        </div>
                        <div className="card-body">
                            <div>
                                <ul
                                    className="nav nav-pills justify-content-between mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-3-1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                            onClick={() => setThreeMonth(249)}
                                        >
                                            1 Kullanıcı
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-3-2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            onClick={() => setThreeMonth(459)}
                                        >
                                            2 Kullanıcı
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-3-1"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=2&user=3">SATIN AL</a>
                                                        </button>
                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-3-2"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek 
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=2&user=4">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 mt-xl-0 mt-4">
                    <div className="card border-0 shadow text-center service-card">
                        <div className="card-header border-bottom pt-4 bg-transparent border-0">
                            <div className="service-card-icon mb-3">
                                <img src={P2} alt="icon" weight="auto" height="auto"/>
                            </div>
                            <h5 className="service-card-title mb-0">
                                6 AYLIK iPTV
                            </h5>
                            <p className="mb-0 service-card-price">
                                {sixMonth}<span>₺</span>
                            </p>
                        </div>
                        <div className="card-body">
                            <div>
                                <ul
                                    className="nav nav-pills justify-content-between mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-6-1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                            onClick={() => setSixMonth(399)}
                                        >
                                            1 Kullanıcı
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-6-2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            onClick={() => setSixMonth(759)}
                                        >
                                            2 Kullanıcı
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-6-1"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek 
                                            </div>
                                        </div>
                                                                <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=3&user=5">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-6-2"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=3&user=6">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-xl-3 mt-xl-0 mt-4">
                    <div className="card border-0 shadow text-center service-card">
                        <div className="card-header border-bottom pt-4 bg-transparent border-0">
                            <div className="service-card-icon mb-3">
                                <img src={P3} alt="icon" weight="auto" height="auto" />
                            </div>
                            <h5 className="service-card-title mb-0">
                                12 AYLIK iPTV
                            </h5>
                            <p className="mb-0 service-card-price">
                                {twelveMonth}<span>₺</span>
                            </p>
                        </div>
                        <div className="card-body">
                            <div>
                                <ul
                                    className="nav nav-pills justify-content-between mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-12-1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                            onClick={() => setTwelveMonth(599)}
                                        >
                                            1 Kullanıcı
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-12-2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            onClick={() => setTwelveMonth(1049)}
                                        >
                                            2 Kullanıcı
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-12-1"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek 
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=4&user=7">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-12-2"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek 
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=4&user=8">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sales-badge">
                            %37 indirim
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-3 mt-xl-0 mt-4">
                    <div className="card border-0 shadow text-center service-card">
                        <div className="card-header border-bottom pt-4 bg-transparent border-0">
                            <div className="service-card-icon mb-3">
                                <img src={P4} alt="icon" weight="auto" height="auto"/>
                            </div>
                            <h5 className="service-card-title mb-0">
                                24 AYLIK iPTV
                            </h5>
                            <p className="mb-0 service-card-price">
                                {thiryfourMonth}<span>₺</span>
                            </p>
                        </div>
                        <div className="card-body">
                            <div>
                                <ul
                                    className="nav nav-pills justify-content-between mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-24-1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                            onClick={() => setThirtyfourMonth(989)}
                                        >
                                            1 Kullanıcı
                                        </button>
                                    </li>
                                    <li
                                        className="nav-item"
                                        role="presentation"
                                    >
                                        <button
                                            className="nav-link"
                                            id="pills-profile-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-24-2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            onClick={() => setThirtyfourMonth(1499)}
                                        >
                                            2 Kullanıcı
                                        </button>
                                    </li>
                                </ul>
                                <div
                                    className="tab-content"
                                    id="pills-tabContent"
                                >
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-24-1"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek 
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=5&user=9">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-24-2"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                        tabIndex={0}
                                    >
                                        <div className="service-card-list">
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-tv me-1"></i>{" "}
                                                Premium Kanallar 
                                            </div>
                                            <div className="d-flex align-items-center mb-3 service-card-info-texts">
                                                <i className="fa-solid fa-film me-1"></i>{" "}
                                                Güncel Film ve Dizi 
                                            </div>
                                            <div className="d-flex align-items-center service-card-info-texts">
                                                <i className="fa-solid fa-headset me-1"></i>{" "}
                                                7/24 Canlı Destek 
                                            </div>
                                        </div>
                                        <div className="card-footer pb-4 bg-transparent border-0">
                            <button className="btn service-card-btn w-100">
                            <a href="https://azurehost.net/payment/index.php?paket=5&user=10">SATIN AL</a>
                            </button>
                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
};

export default Services;
