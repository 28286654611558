import React from 'react'
import Logo from "../assets/img/logo.svg"
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div className='container'>
            <div className="row">
                <div className="col-xl-5">
                    <img src={Logo} alt="" width="225px" className='mb-4' />
                    <p className='footer-text'>Azure iPTV kullanıcılarına 9 yıldan fazla bir süredir hizmet veren bir firmadır. iPTV dünyasında en çok tercih edilenler arasında yer almaktadır.</p>
                </div>
                <div className="col-xl-4 col-6 mt-xl-0 mt-5">
                    <h4 className='mb-4 footer-subtitle'>Bağlantılar</h4>
                    <div className="row footer-menu-links">
                        <div className="col-xl-6 mb-3"><i className="fa-solid fa-chevron-right me-1"></i> <a href="https://azureiptv.com/">iPTV Satın Al</a></div>
                        <div className="col-xl-6 mb-3"><i className="fa-solid fa-chevron-right me-1"></i> <a href="https://azureiptv.com/">Ucuz iPTV</a></div> 
                        <div className="col-xl-6 mb-3"><i className="fa-solid fa-chevron-right me-1"></i> <a href="https://azureiptv.com/">Kaliteli iPTV</a></div>
                        <div className="col-xl-6 mb-3"><i className="fa-solid fa-chevron-right me-1"></i> <a href="https://azureiptv.com/">iPTV Fiyatları</a></div>
                        <div className="col-xl-6"><i className="fa-solid fa-chevron-right me-1"></i> <a href="https://azureiptv.com/">AzureiPTV</a></div>
                    </div>
                </div>
                <div className="col-xl-3 col-6 mt-xl-0 mt-5">
                    <h4 className='mb-4 footer-subtitle'>İletişim</h4>
                    <div className='footer-menu-medias'>
                        <div className='d-flex mb-3 align-items-center'><i className="fa fa-telegram me-xl-2 me-1"></i><a href="https://t.me/azuretv">AzureTV</a></div>
                        <div className='d-flex mb-3 align-items-center'><i className="fa-brands fa-instagram me-xl-2 me-1"></i><a href="https://instagram.com/tvazure5">TvAzure5</a></div>
                        <div className='d-flex align-items-center'><i className="fa-brands fa-twitter me-xl-2 me-1"></i><a href="https://twitter.com/tvazure5">TvAzure5</a></div>
                        
                    </div>
                </div>
            </div>
        </div>

        
    )
}

export default Footer